<template lang="pug">
AppLayout
  template(v-slot:header)
    v-spacer
    v-toolbar-title.cash-register-name.mx-2.align-center Прайс
    v-spacer
    v-icon mdi-calendar
  Prices
</template>
<script>
import AppLayout from 'T@/AppLayout.vue'
import Prices from './price/Price.vue'

export default {
  components: {
    AppLayout,
    Prices,
    Basket: () => import('T@/components/cart/Basket.vue'),
  },
}
</script>
<style lang="stylus"></style>
